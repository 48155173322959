import {
  AppointmentResponse,
  fetchAppointment,
} from '../../services/kantan-client';
import { useAsyncResult } from './use-async-result';

export const useAppointment = (
  appointmentId: string | undefined,
): [AppointmentResponse | undefined, boolean, Error | undefined] => {
  const [appointment, isLoading, error] = useAsyncResult(async () => {
    if (!appointmentId) {
      return;
    }
    return await fetchAppointment(appointmentId);
  }, [appointmentId]);

  return [appointment, isLoading, error];
};
