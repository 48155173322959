/**
 * Generated using svgr with the react-native option and a custom template
 * from the following file:
 * https://www.figma.com/file/Qul4lqXRzrrmmgHFgRyrmB/Brand?node-id=1401%3A1458
 */

import * as React from 'react';
type Props = { color?: string };

export const ArrowRightIcon: React.FC<Props> = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" {...svgProps}>
      <path
        className="path"
        fill={color || 'black'}
        d="M0.164978 18.13L1.93498 19.9L11.835 10L1.93498 0.100006L0.164978 1.87001L8.29498 10L0.164978 18.13H0.164978Z"
      />
    </svg>
  );
};
