import { captureException } from '@sentry/browser';
import {
  addWeeks,
  endOfDay,
  endOfWeek,
  isPast,
  isToday,
  startOfDay,
  startOfWeek,
} from 'date-fns';

import { config } from '../../config';
import {
  BookableSlotResponse,
  fetchBookableSlots,
  fetchSlotRating,
} from '../../services/kantan-client';
import { useAsyncResult } from './use-async-result';

export type BookableSlot = {
  startDateTime: Date;
  endDateTime: Date;
  isAvailable: boolean;
  isRatingGood: boolean | undefined;
};

export type SlotAvailability = {
  bookableSlots: BookableSlot[];
  rejectedRatingSlots: BookableSlotResponse[];
};

/**
 * Maps nominal slot times to times suitable for querying jobs.
 *
 * This is necessary because the start and end times of booking slots don't include all possible job start and end
 * times, and we sometimes need to show jobs outside these times as if they were in within a nominal time slot. Also
 * the back end expects _inclusive_ time ranges, but it's natural in the UI to talk about _exclusive_ ranges (where
 * a job at 12:00 would be in slot 12:00-18:00 and not in slot 8:00-12:00)
 *
 * @param slotStart  nominal slot start time in ISO string format
 * @param slotEnd  nominal slot end time in ISO string format
 * @returns {[rangeStart:Date, rangeEnd:Date]}  time range suitable for querying jobs.
 */
const timeSlotToTimeRange = (
  slotStart: string,
  slotEnd: string,
): [Date, Date] => {
  const slotStartDateTime = new Date(slotStart);
  const slotEndDateTime = new Date(slotEnd);
  const rangeStartMilliseconds =
    slotStartDateTime.getHours() < 12
      ? startOfDay(slotStartDateTime).getTime()
      : slotStartDateTime.getTime();
  const rangeEndMilliseconds =
    slotEndDateTime.getHours() > 18
      ? endOfDay(slotEndDateTime).getTime()
      : slotEndDateTime.getTime() - 1;
  return [new Date(rangeStartMilliseconds), new Date(rangeEndMilliseconds)];
};

type CalculatedAvailability = {
  isAvailable: boolean;
  isRatingGood?: boolean;
};

const calculateAvailable = async (
  appointmentId: string,
  slot: BookableSlotResponse,
  onRatingRejection?: (slot: BookableSlotResponse) => void,
): Promise<CalculatedAvailability> => {
  const [startDateTime, endDateTime] = timeSlotToTimeRange(
    slot.startDateTime,
    slot.endDateTime,
  );

  const { isAvailable, isFullyBooked, appointments } = slot;

  // Definitely not available
  if (
    isPast(startDateTime) ||
    isToday(startDateTime) ||
    !isAvailable ||
    isFullyBooked
  ) {
    return { isAvailable: false };
  }

  // Definitely available
  if (isAvailable && appointments.length === 0) {
    return { isAvailable: true };
  }

  // Available if it has a confirmed good rating
  const rating = await fetchSlotRating(
    appointmentId,
    startDateTime,
    endDateTime,
  );

  const isRatingGood = rating.score >= config.slotRating.threshold;
  if (!isRatingGood && onRatingRejection) {
    onRatingRejection(slot);
  }

  return {
    isAvailable: isRatingGood,
    isRatingGood,
  };
};

const fetchSlotsWithAvailability = async (
  appointmentId: string,
  startDateTime: Date,
  endDateTime: Date,
): Promise<SlotAvailability> => {
  const fetchedSlots = await fetchBookableSlots(
    appointmentId,
    startDateTime,
    endDateTime,
  );
  const rejectedRatingSlots: BookableSlotResponse[] = [];

  const asyncSlotsWithAvailability = fetchedSlots.map(async (slot) => {
    const startDateTime = new Date(slot.startDateTime);
    const endDateTime = new Date(slot.endDateTime);
    let calculatedAvailability;

    try {
      calculatedAvailability = await calculateAvailable(
        appointmentId,
        slot,
        (slot) => rejectedRatingSlots.push(slot),
      );
    } catch (e) {
      captureException(e);
      calculatedAvailability = { isAvailable: false };
    }

    const { isAvailable, isRatingGood } = calculatedAvailability;

    return {
      startDateTime,
      endDateTime,
      isAvailable,
      isRatingGood,
    };
  });

  const bookableSlots = await Promise.all(asyncSlotsWithAvailability);

  return {
    bookableSlots,
    rejectedRatingSlots,
  };
};

type SlotRange = {
  startDateTime: Date;
  endDateTime: Date;
};

export const calculateSlotRanges = (
  anchorDateTime: Date,
  options: {
    initialWeeks: number;
    maxWeeks: number;
  },
): Array<SlotRange> => {
  // Calculate initial range
  const startDateTime = startOfWeek(anchorDateTime, { weekStartsOn: 1 });
  const endDateTime = endOfWeek(
    addWeeks(startDateTime, options.initialWeeks - 1),
    {
      weekStartsOn: 1,
    },
  );
  const ranges: SlotRange[] = [
    {
      startDateTime,
      endDateTime,
    },
  ];

  // Push week ranges onto list, up to maxWeeks
  for (let i = options.initialWeeks; i < options.maxWeeks; i += 1) {
    const startDateTime = addWeeks(
      startOfWeek(anchorDateTime, { weekStartsOn: 1 }),
      i,
    );
    const endDateTime = endOfWeek(startDateTime, { weekStartsOn: 1 });
    ranges.push({ startDateTime, endDateTime });
  }

  return ranges;
};

/**
 *
 * @param appointmentId Which appointment is being booked.
 * @param ranges The blocks of time that will be queried until availability criteria is reached.
 * They will be queried in order and results accumulated until the accumulated slots match the criteria.
 * @param options Options for controlling availability criteria (e.g. minimum number of available slots)
 */
export const useBookableSlots = (
  appointmentId: string | undefined,
  ranges: Array<SlotRange>,
  options: {
    minAvailableSlots: number;
  } = {
    minAvailableSlots: 0,
  },
): [SlotAvailability | undefined, boolean, Error | undefined] => {
  const [slotAvailability, isLoading, error] = useAsyncResult(async () => {
    if (!appointmentId) {
      return;
    }

    const acc: SlotAvailability = {
      bookableSlots: [],
      rejectedRatingSlots: [],
    };

    for (const { startDateTime, endDateTime } of ranges) {
      const avail = await fetchSlotsWithAvailability(
        appointmentId,
        startDateTime,
        endDateTime,
      );

      // Merge new availabilities
      acc.bookableSlots = [...acc.bookableSlots, ...avail.bookableSlots];
      acc.rejectedRatingSlots = [
        ...acc.rejectedRatingSlots,
        ...avail.rejectedRatingSlots,
      ];

      // Check if we've reached our goal
      const totalAvailableSlots = acc.bookableSlots.filter(
        (slot) => slot.isAvailable,
      ).length;

      if (totalAvailableSlots >= options.minAvailableSlots) {
        break;
      }
    }

    return acc;
  }, [appointmentId, options.minAvailableSlots, ranges]);

  return [slotAvailability, isLoading, error];
};
