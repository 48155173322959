import {
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameWeek,
  setHours,
} from 'date-fns';

import { BookableSlot } from './use-bookable-slots';

/**
 * Convert a date string plus a slot string into two complete start and end date time strings.
 *
 * @param date Date string (example: "2020-04-21")
 * @param slot Slot string indicating slot start and end hours (example: "8-12")
 */
export const toStartAndEndDateTime = (
  date: string,
  slot: string,
): [Date, Date] => {
  const [startHour, endHour] = slot
    .split('-')
    .map((hourStr) => Number(hourStr));

  const startDateTime = setHours(new Date(date), startHour);
  const endDateTime = setHours(new Date(date), endHour);
  return [startDateTime, endDateTime];
};

export type TimeSlot = {
  date: Date;
  slot: string;
};

/**
 * Encode a Date and slot string to a single timeslot string value.
 *
 * @param date
 * @param slot
 */
export const toTimeSlotValue = ({ date, slot }: TimeSlot): string => {
  const valueDate = format(date, 'yyyy-MM-dd');
  return `${valueDate}||${slot}`;
};

/**
 * Split a string that has been encoded with @function toTimeSlotString into a date string and a slot string.
 *
 * @param timeSlotString Format: "2020-04-21||8-12"
 */
export const splitTimeSlotValue = (timeSlotValue: string): [string, string] => {
  const [date, slot] = timeSlotValue.split('||');
  return [date, slot];
};

export const toTimeSlot = (
  startDateTime: Date,
  endDateTime: Date,
): TimeSlot => {
  const startHour = format(startDateTime, 'HH');
  const endHour = format(endDateTime, 'HH');
  const slot = `${startHour}-${endHour}`;
  return {
    date: startDateTime,
    slot,
  };
};

export const sortByStart = (bookableSlots: BookableSlot[]): BookableSlot[] => {
  return bookableSlots.sort((a, b) =>
    isBefore(a.startDateTime, b.startDateTime)
      ? -1
      : isAfter(a.startDateTime, b.startDateTime)
      ? 1
      : 0,
  );
};

export const groupByDay = (bookableSlots: BookableSlot[]): BookableSlot[][] => {
  const grouped = bookableSlots.reduce<BookableSlot[][]>((acc, slot) => {
    if (acc.length === 0) {
      return [[slot]];
    }

    const lastDay = acc[acc.length - 1];
    if (isSameDay(lastDay[0].startDateTime, slot.startDateTime)) {
      lastDay.push(slot);
      return acc;
    }

    return [...acc, [slot]];
  }, []);

  return grouped;
};

export const groupByWeek = (
  bookableSlots: BookableSlot[][],
): BookableSlot[][][] => {
  const grouped = bookableSlots.reduce<BookableSlot[][][]>((acc, day) => {
    if (acc.length === 0) {
      return [[day]];
    }

    const lastWeek = acc[acc.length - 1];
    if (
      isSameWeek(lastWeek[0][0].startDateTime, day[0].startDateTime, {
        weekStartsOn: 1,
      })
    ) {
      lastWeek.push(day);
      return acc;
    }

    return [...acc, [day]];
  }, []);

  return grouped;
};

export const getAvailableSlotsPerWeek = (
  weeks: BookableSlot[][][],
  firstN = 3,
): number[] => {
  return weeks.slice(0, firstN).map<number>((week) => {
    return week.reduce<number>((dailyTotal, day) => {
      return dailyTotal + day.filter((slot) => slot.isAvailable).length;
    }, 0);
  });
};
