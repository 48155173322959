import React from 'react';

export type CalendarSlotInputProps = {
  title: string;
  group: string;
  value: string;
  disabled: boolean;
  isEcoSlot: boolean;
  inputRef?: (instance: HTMLInputElement | null) => void;
};

export const CalendarSlotInput: React.FC<CalendarSlotInputProps> = ({
  title,
  group,
  value,
  disabled,
  inputRef,
  isEcoSlot,
}) => {
  const toolTipText = 'Not available';

  return (
    <div className="calendar__time-slot">
      {disabled && <div data-tip={toolTipText} className="empty-slot"></div>}
      {!disabled && (
        <>
          <input
            type="radio"
            ref={inputRef}
            id={title}
            name={group}
            value={value}
            disabled={disabled}
          />
          {isEcoSlot ? (
            <div className="radio-circle radio-circle--rated-good"></div>
          ) : (
            <div className="radio-circle"></div>
          )}
          <label className="screen-reader" htmlFor={title}>
            {title}
          </label>
        </>
      )}
    </div>
  );
};
