import '../branding/branding.scss';

import { RouteComponentProps, Router } from '@reach/router';
import { graphql, PageProps } from 'gatsby';
import * as React from 'react';

import { BookingEntry } from '../components/booking-entry';
import { Calendar } from '../components/calendar';
import { Confirmation } from '../components/confirmation';
import { Layout, LayoutBrandData } from '../components/layout';
import { PageNotFound } from '../components/page-not-found';
import { SEO } from '../components/seo';

// Route wrappers

const PageNotFoundRoute: React.FC<RouteComponentProps> = () => <PageNotFound />;

const BookingRoute: React.FC<RouteComponentProps<{
  appointmentId: string;
}>> = ({ appointmentId }) => <BookingEntry appointmentId={appointmentId} />;

const CalendarRoute: React.FC<RouteComponentProps<{
  appointmentId: string;
}>> = ({ appointmentId }) => <Calendar appointmentId={appointmentId} />;

const ConfirmationRoute: React.FC<RouteComponentProps<{
  appointmentId: string;
}>> = ({ appointmentId }) => <Confirmation appointmentId={appointmentId} />;

interface BrandNode extends LayoutBrandData {
  slug: string;
}

type QueryData = {
  brand: BrandNode;
};

type BoilerServicePageProps = PageProps<QueryData>;

const BoilerServicePage: React.FC<BoilerServicePageProps> = ({ data }) => {
  const { brand } = data;

  return (
    <Layout brand={brand}>
      <SEO title="Annual Boiler Service" />
      <Router basepath={`/${brand.slug}/:appointmentId`}>
        <BookingRoute path="/booking" />
        <CalendarRoute path="/calendar" />
        <ConfirmationRoute path="/confirmation" />
        <PageNotFoundRoute path="/" default />
      </Router>
    </Layout>
  );
};

export const query = graphql`
  # Fetch data from brands.json
  query BoilerServiceBrandInfo($brandId: String) {
    site {
      siteMetadata {
        title
      }
    }
    brand: brandsJson(id: { eq: $brandId }) {
      ...LayoutBrandFragment
      slug
    }
  }
`;

export default BoilerServicePage;
