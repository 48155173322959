import { format } from 'date-fns';
import * as React from 'react';

import { splitTimeSlotValue } from './utils';

type CalendarSubmitProps = {
  timeSlot: string | undefined;
  isSubmitting: boolean;
};

export const CalendarSubmit: React.FC<CalendarSubmitProps> = ({
  timeSlot,
  isSubmitting,
}) => {
  const toPrettyTimeSlot = (timeSlotValue: string) => {
    const [date, slot] = splitTimeSlotValue(timeSlotValue);
    const prettyDate = format(new Date(date), 'EEEE d MMMM');

    let prettyTime;

    switch (slot) {
      case '08-12':
        prettyTime = '8am-12pm';
        break;
      case '12-18':
        prettyTime = '12pm-6pm';
        break;
      case '18-21':
        prettyTime = '6pm-9pm';
        break;
      default:
        prettyTime = '';
        break;
    }

    return (
      <>
        {prettyDate}, <span className="no-break">{prettyTime}</span>
      </>
    );
  };

  if (!timeSlot) {
    return <p className="choose">Please choose a time slot for your booking</p>;
  }

  return (
    <>
      <div>
        <p>You have chosen:</p>
        <p className="booking__choice">{toPrettyTimeSlot(timeSlot)}</p>
      </div>
      <div className="button-wrap">
        <button
          className="button--primary"
          type="submit"
          disabled={isSubmitting}
        >
          Book
        </button>
      </div>
    </>
  );
};
