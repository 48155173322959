import '../styles/components/booking.scss';

import { navigate } from '@reach/router';
import { captureException } from '@sentry/browser';
import { differenceInCalendarDays } from 'date-fns';
import React from 'react';
import { useForm } from 'react-hook-form';

import * as analytics from '../services/analytics';
import {
  bookAppointment,
  setContactAvailability,
} from '../services/kantan-client';
import { CalendarInput } from './calendar/calendar-input';
import { CalendarKey } from './calendar/calendar-key';
import { CalendarSubmit } from './calendar/calendar-submit';
import { useAppointment } from './calendar/use-appointment';
import {
  calculateSlotRanges,
  SlotAvailability,
  useBookableSlots,
} from './calendar/use-bookable-slots';
import {
  getAvailableSlotsPerWeek,
  splitTimeSlotValue,
  toStartAndEndDateTime,
} from './calendar/utils';
import { groupByDay, groupByWeek, sortByStart } from './calendar/utils';
import { Spinner } from './spinner';

const buildSharedAnalyticsData = (
  appointmentId: string,
  slotsShownPerWeek: number[],
  numSlotsRejectedByRating: number,
) => ({
  /* eslint-disable @typescript-eslint/camelcase */
  appointment_id: appointmentId,
  num_slots_shown_in_portal_week1: slotsShownPerWeek[0],
  num_slots_shown_in_portal_week2: slotsShownPerWeek[1],
  num_slots_shown_in_portal_week3: slotsShownPerWeek[2],
  num_slots_shown_in_portal_week4: slotsShownPerWeek[3],
  num_slots_shown_in_portal_week5: slotsShownPerWeek[4],
  num_slots_shown_in_portal_week6: slotsShownPerWeek[5],
  num_slots_discarded_by_rating_system: numSlotsRejectedByRating,
  /* eslint-enable @typescript-eslint/camelcase */
});

const trackPageViewed = (
  appointmentId: string,
  slotsShownPerWeek: number[],
  numSlotsRejectedByRating: number,
) => {
  analytics.trackPageView(
    'Portal-Booking page viewed',
    buildSharedAnalyticsData(
      appointmentId,
      slotsShownPerWeek,
      numSlotsRejectedByRating,
    ),
  );
};

const trackBookingMade = (
  appointmentId: string,
  startDateTime: Date,
  endDateTime: Date,
  slotsShownPerWeek: number[],
  numSlotsRejectedByRating: number,
) => {
  analytics.trackEvent('Portal-Job booked', {
    /* eslint-disable @typescript-eslint/camelcase */
    ...buildSharedAnalyticsData(
      appointmentId,
      slotsShownPerWeek,
      numSlotsRejectedByRating,
    ),
    selected_slot_start: startDateTime,
    selected_slot_end: endDateTime,
    selected_slot_days_ahead: differenceInCalendarDays(
      startDateTime,
      new Date(),
    ),
    /* eslint-enable @typescript-eslint/camelcase */
  });
};

const useCaptureError = (error: Error | undefined) => {
  React.useEffect(() => {
    if (error) {
      captureException(error);
    }
  }, [error]);
};

const useBookingPageSlotData = (
  appointmentId: string | undefined,
): [SlotAvailability | undefined, boolean] => {
  const slotRanges = React.useMemo(
    () =>
      calculateSlotRanges(new Date(), {
        initialWeeks: 3,
        maxWeeks: 6,
      }),
    [],
  );
  const [slotAvailability, isLoading, slotsError] = useBookableSlots(
    appointmentId,
    slotRanges,
    {
      minAvailableSlots: 1,
    },
  );

  useCaptureError(slotsError);

  return [slotAvailability, isLoading];
};

interface AppointmentValues {
  timeSlot: string | undefined;
}

type CalendarProps = { appointmentId: string | undefined };

export const Calendar: React.FC<CalendarProps> = ({ appointmentId }) => {
  const defaultValues: AppointmentValues = {
    timeSlot: undefined,
  };

  const { register, handleSubmit, formState, watch } = useForm<
    AppointmentValues
  >({
    defaultValues,
  });

  const [slotAvailability, isSlotsLoading] = useBookingPageSlotData(
    appointmentId,
  );

  const [appointment, isLoading, appointmentError] = useAppointment(
    appointmentId,
  );

  useCaptureError(appointmentError);

  const sortedSlots = sortByStart(slotAvailability?.bookableSlots ?? []);
  const weeks = groupByWeek(groupByDay(sortedSlots));
  const slotsShownPerWeek = getAvailableSlotsPerWeek(weeks);

  // Track pageview once appointment and slots have loaded
  React.useEffect(() => {
    if (appointment && slotAvailability) {
      trackPageViewed(
        appointment.id,
        slotsShownPerWeek,
        slotAvailability.rejectedRatingSlots.length,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment, slotAvailability]);

  const submitBooking = async (values: AppointmentValues) => {
    if (!appointmentId || !values.timeSlot) {
      return;
    }

    const [date, slot] = splitTimeSlotValue(values.timeSlot);
    const [startDateTime, endDateTime] = toStartAndEndDateTime(date, slot);

    try {
      await setContactAvailability(startDateTime, endDateTime);
      await bookAppointment(appointmentId, startDateTime, endDateTime);
      if (slotAvailability) {
        trackBookingMade(
          appointmentId,
          startDateTime,
          endDateTime,
          slotsShownPerWeek,
          slotAvailability.rejectedRatingSlots.length,
        );
      }
      navigate('confirmation', { replace: true });
    } catch (err) {
      // TODO: Display an error to user
      captureException(err);
    }
  };

  const timeSlot = watch('timeSlot');

  React.useEffect(() => {
    const eventListener = (event: BeforeUnloadEvent) => {
      if (timeSlot) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', eventListener);

    return () => {
      window.removeEventListener('beforeunload', eventListener);
    };
  });

  if (isLoading || isSlotsLoading) {
    return <Spinner />;
  }

  if (!appointment || !slotAvailability) {
    return (
      <section className="section message">
        <div className="container--small center">
          <h2 className="heading2">Something went wrong</h2>
          <p className="faded-text">We couldn&apos;t find your appointment.</p>
        </div>
      </section>
    );
  }

  return (
    <section className="booking">
      <div className="section booking__intro">
        <div className="container--small">
          <h2 className="heading2">Choose a time</h2>
          <p>
            Times your engineer is available are shown on the calendar below.
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit(submitBooking)} className="calendar">
        <div className="container--small no-padding">
          <CalendarInput
            name="timeSlot"
            bookableSlots={sortedSlots}
            inputRef={register({ required: true })}
          />
          <CalendarKey />
        </div>

        <section className="booking__footer">
          <div className="container--small">
            <CalendarSubmit
              timeSlot={timeSlot}
              isSubmitting={formState.isSubmitting}
            />
          </div>
        </section>
      </form>
    </section>
  );
};
