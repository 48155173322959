import React from 'react';

export const CalendarTimes = () => {
  const toolTipText = 'Select from the available times below';

  return (
    <div className="calendar__times">
      <div data-tip={toolTipText}>
        <h3>Morning</h3>
        <p>8am- 12pm</p>
      </div>
      <div data-tip={toolTipText}>
        <h3>Afternoon</h3>
        <p>12pm- 6pm</p>
      </div>
      <div data-tip={toolTipText}>
        <h3>Evening</h3>
        <p>6pm- 9pm</p>
      </div>
    </div>
  );
};
