import { navigate } from '@reach/router';
import { captureException, captureMessage, Severity } from '@sentry/browser';
import { addDays, format, isAfter, startOfDay } from 'date-fns';
import * as React from 'react';

import { useAppointment } from './calendar/use-appointment';
import { Card } from './card';
import { CalendarIcon } from './icons/CalendarIcon';
import { Spinner } from './spinner';

const toPrettyDate = (dateTime: string) => {
  const prettyDate = format(new Date(dateTime), 'EEEE d MMMM');
  return `${prettyDate}`;
};

const toPrettyTime = (dateTime: string) => {
  const prettyTime = format(new Date(dateTime), 'ha');
  return prettyTime.toLowerCase();
};

const RESCHEDULE_ALLOWANCE_IN_DAYS = 2;

type ConfirmationProps = { appointmentId: string | undefined };

export const Confirmation: React.FC<ConfirmationProps> = ({
  appointmentId,
}) => {
  const [appointment, isLoading, error] = useAppointment(appointmentId);

  // Capture error states
  React.useEffect(() => {
    if (error) {
      captureException(error);
    } else if (!isLoading && !appointment) {
      captureMessage('Failed to load appointment', Severity.Error);
    } else if (
      !isLoading &&
      (!appointment?.fulfillmentWindow.endDateTime ||
        !appointment.fulfillmentWindow.startDateTime)
    ) {
      captureMessage('Failed to book appointment', Severity.Error);
    }
  }, [appointment, error, isLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  if (
    !appointment ||
    !appointment.fulfillmentWindow.startDateTime ||
    !appointment.fulfillmentWindow.endDateTime
  ) {
    return (
      <section className="section message">
        <div className="container--small center">
          <h1 className="heading2">Something went wrong</h1>
          <p className="faded-text">We couldn&apos;t find your appointment.</p>
        </div>
      </section>
    );
  }

  const { startDateTime, endDateTime } = appointment.fulfillmentWindow;

  const canBeRescheduled = isAfter(
    new Date(startDateTime),
    startOfDay(addDays(new Date(), RESCHEDULE_ALLOWANCE_IN_DAYS)),
  );

  const appointmentDate = toPrettyDate(startDateTime);

  const appointmentTime = `${toPrettyTime(startDateTime)}-${toPrettyTime(
    endDateTime,
  )}`;

  return (
    <>
      <section className="section ">
        <div className="container--small">
          <h1 className="heading2">Your service has been booked</h1>
          <p>
            We’ve sent you a confirmation email. You can now close this window.
          </p>
          {canBeRescheduled && (
            <button
              type="button"
              className="button--primary"
              onClick={() => navigate('calendar', { replace: true })}
            >
              Reschedule Booking
            </button>
          )}
        </div>
      </section>
      <section className="section">
        <div className="container--small">
          <Card
            icon={<CalendarIcon />}
            title="Appointment"
            line1={appointmentDate}
            line2={appointmentTime}
          />
        </div>
      </section>
    </>
  );
};
