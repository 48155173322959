import { format } from 'date-fns';
import * as React from 'react';

type CalendarDayProps = {
  date: Date;
};

export const CalendarDay: React.FC<CalendarDayProps> = ({ date, children }) => {
  const displayDate = format(date, 'EEEE');
  return (
    <div className="calendar__day">
      <p className="calendar__date">{displayDate}</p>
      {children}
    </div>
  );
};
