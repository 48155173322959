import { addDays, format, getMonth } from 'date-fns';
import * as React from 'react';

const getDateRangeLabel = (date1: Date, date2: Date) => {
  if (getMonth(date1) === getMonth(date2)) {
    return (
      <>
        {format(date1, 'd')}
        {''}-{''}
        <span className="no-break">{format(date2, 'd MMMM')}</span>
      </>
    );
  }
  return (
    <>
      {format(date1, 'd MMMM')}
      {''}-{''}
      <span className="no-break">{format(date2, 'd MMMM')}</span>
    </>
  );
};

type CalendarWeekProps = {
  weekStart: Date;
  title: string | null;
};

export const CalendarWeek: React.FC<CalendarWeekProps> = ({
  children,
  weekStart,
  title,
}) => {
  const weekEnd = addDays(weekStart, 6);
  const dateRange = getDateRangeLabel(weekStart, weekEnd);

  return (
    <div>
      <div className="calendar__dates">
        {title && (
          <>
            <h2>{title}</h2>
            <h3>{dateRange}</h3>
          </>
        )}
        {!title && (
          <>
            <h2 className="calendar__solo-title">{dateRange}</h2>
          </>
        )}
      </div>
      {children}
    </div>
  );
};
