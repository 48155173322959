type Config = {
  slotRating: {
    threshold: number;
  };
};

export const config: Config = {
  slotRating: {
    threshold: 0.9,
  },
};
