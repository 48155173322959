import React from 'react';

import { SEO } from './seo';

export const PageNotFound = () => {
  return (
    <section className="section message">
      <SEO title="Page not found" />
      <div className="container--small center">
        <h2 className="heading2">Page not found</h2>
        <p className="faded-text">
          Sorry, we can&#39;t find the page you are{' '}
          <span className="no-break">looking for</span>
        </p>
      </div>
    </section>
  );
};
