import React from 'react';

/**
 * Executes an async function and returns the result, and loading and error state.
 *
 * @param effect Async function that will be executed.
 * @param deps Dependency list for the async function.
 */
export function useAsyncResult<R>(
  effect: () => Promise<R>,
  deps?: React.DependencyList,
): [R | undefined, boolean, Error | undefined] {
  /**
   *  __NOTE:__ This function is not named `useAsyncEffect` because of a linting rule that
   * activates on hooks named `Effect` specifically forbidding async functions.
   *
   * __NOTE:__ If changing the name of this function, update `react-hooks/exhaustive-deps`
   * rule config as well to ensure dependency list is checked.
   */

  const [effectResult, setEffectResult] = React.useState<R>();
  const [isPending, setPending] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | undefined>();

  React.useEffect(() => {
    async function doAsyncEffect() {
      try {
        setPending(true);
        const result = await effect();
        setEffectResult(result);
      } catch (e) {
        setError(e);
      } finally {
        setPending(false);
      }
    }
    doAsyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [effectResult, isPending, error];
}
