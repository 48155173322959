/**
 * Generated using svgr with the react-native option and a custom template
 * from the following file:
    https://www.figma.com/file/Qul4lqXRzrrmmgHFgRyrmB/Brand?node-id=1401%3A1447
 */

import * as React from 'react';
type Props = { color?: string };

export const CalendarIcon: React.FC<Props> = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        className="path"
        fill={color || 'white'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 4H34V0H30V4H10V0H6V4H4C1.8 4 0 5.8 0 8V40C0 42.2 1.8 44 4 44H36C38.2 44 40 42.2 40 40V8C40 5.8 38.2 4 36 4ZM36 40H4V18H36V40ZM4 14H36V8H4V14Z"
      />
    </svg>
  );
};
