import React from 'react';

export const CalendarKey = () => (
  <div className="key">
    <div className="key-element">
      <div className="radio-circle"></div>
      <p>Your engineer is available</p>
    </div>
    <div className="key-element">
      <div className="radio-circle radio-circle--rated-good"></div>
      <p>Your engineer is nearby</p>
    </div>
  </div>
);
