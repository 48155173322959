import * as React from 'react';

type CardProps = {
  icon: JSX.Element;
  title: string;
  line1: string;
  line2: string;
};

export const Card: React.FC<CardProps> = ({ icon, title, line1, line2 }) => {
  return (
    <div className="card">
      <div className="card__icon">{icon}</div>
      <div className="card__details">
        <h2 className="heading3">{title}</h2>
        <p>{line1}</p>
        <p>{line2}</p>
      </div>
    </div>
  );
};
