import { navigate } from '@reach/router';
import { captureException } from '@sentry/browser';
import React from 'react';

import { AppointmentResponse } from '../services/kantan-client';
import { useAppointment } from './calendar/use-appointment';
import { Spinner } from './spinner';

const useCaptureError = (error: Error | undefined) => {
  React.useEffect(() => {
    if (error) {
      captureException(error);
    }
  }, [error]);
};

const useCheckExistingAppointmentWithRedirect = (
  appointmentId: string | undefined,
): [AppointmentResponse | undefined, boolean] => {
  const [appointment, isLoading, appointmentError] = useAppointment(
    appointmentId,
  );

  useCaptureError(appointmentError);
  React.useEffect(() => {
    if (!isLoading && appointment) {
      if (appointment.fulfillmentWindow.startDateTime) {
        navigate('confirmation', { replace: true });
      } else {
        navigate('calendar', { replace: true });
      }
    }
  }, [appointment, isLoading]);
  return [appointment, isLoading];
};

type BookingEntryProps = { appointmentId: string | undefined };

export const BookingEntry: React.FC<BookingEntryProps> = ({
  appointmentId,
}) => {
  useCheckExistingAppointmentWithRedirect(appointmentId);

  return (
    <div className="section">
      <div className="container">
        <Spinner />
      </div>
    </div>
  );
};
